import React from 'react';
import Slider from 'react-slick';
import ModalImage from 'react-modal-image';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AssetImagesSlider.css';

const AssetImagesSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
/*         <div key={index} className="image-container">
          <ModalImage
            small={image}
            large={image}
            alt={`Asset Image ${index + 1}`}
          />
        </div> */
        <div key={index} className="image-container">
      <img src={image} alt={`Asset Image ${index + 1}`} />
    </div>
      ))}
    </Slider>
  );
};

export default AssetImagesSlider;
