import React, { useState, useEffect,useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mainpage } from "../components/main";
import { envIp } from '../Config/env';
import { Container } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

// Example for Dashboard.jsx
const Dashboard = () => {
   const [tabloVerileri, setTabloVerileri] = useState([]);
  const [durumVerileri, setDurumVerileri] = useState({});
  const [aktifDemirbaslar, setAktifDemirbaslar] = useState({});
  const [pasifDemirbaslar, setPasifDemirbaslar] = useState({});
  const [buAyOlusturulanDemirbaslar, setBuAyOlusturulanDemirbaslar] = useState({});

  useEffect(() => {
    // API isteği ve veri işleme
    const fetchDashboardData = async () => {
      try {
        const storedUserData = localStorage.getItem('userData');
        const userData = storedUserData ? JSON.parse(storedUserData) : null;
        const token = userData ? userData.token : '';

        const response = await fetch(`${envIp}asset/getAssetDashBord`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        if (!response.ok) {
          throw new Error('Dashboard verisi yüklenirken bir hata oluştu!');
        }

        const data = await response.json();
        if (data.status === "Ok") {
          setTabloVerileri(data.data[0]);
          setDurumVerileri(data.data[1][0]);
          setAktifDemirbaslar(data.data[2][0]);
          setPasifDemirbaslar(data.data[3][0]);
          setBuAyOlusturulanDemirbaslar(data.data[4][0]);
        } else {
          throw new Error('Yanıt beklenen formatta değil.');
        }
      } catch (error) {
        console.error('Dashboard verisi yüklenirken bir hata meydana geldi:', error);
        toast.error('Dashboard verisi yüklenirken bir hata oluştu! ' + error.message);
      }
    };

    fetchDashboardData();
  }, []);
  const columns = useMemo(() => [
    {
      name: 'DEMİRBAŞ KODU',
      accessor: 'DemirbasKodu', // Veri anahtarı
      selector: row => row.DemirbasKodu,
      sortable: true,
    },
    {
      name: 'LOKASYON',
      accessor: 'Lokasyon',
      selector: row => row.Lokasyon,
      sortable: true,
    },
    {
      name: 'BÖLÜM',
      accessor: 'Bolum',
      selector: row => row.Bolum,
      sortable: true,
    },
    {
      name: 'GRUP',
      accessor: 'Grup',
      selector: row => row.Grup,
      sortable: true,
    },
    {
      name: 'DEMİRBAS ADI',
      accessor: 'DemirbasAdı',
      sortable: true,
      selector: row => row.DemirbasAdı,
      cell: value => <Link to={`/asset/${value.DemirbasKodu}`}>{value.DemirbasAdı}</Link>,
    },
    // Diğer sütunlarınız...
  ], []);


  const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            color: '#FFFFFF',  
          backgroundColor: '#763B5C', 
         
        
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
  };
  
  // React Table hooklarını kullanarak tablo instance'ını oluşturma
  // ...
// useTable hook'unu çağırırken data olarak tabloVerileri'ni kullan



    return (
        <Container>
      <section id="dashboard">
  
      <div className="rows">
          <div className="status-bar">
              <ul>
                  <li className="progres">
                      <div className="icon">
                          <img src="images/icon/progress.png" alt=""/>
                      </div>
                      <div className="content">
                    <div className="numb" data-from="0" data-to={durumVerileri['TOPLAM DEMİRBAŞ']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['TOPLAM DEMİRBAŞ']}</div>
                          <div className="text">
                              TOPLAM
                          </div> 

                       
                      </div>
                  </li>
                  <li className="progres">
                      <div className="icon">
                          <img src="images/icon/task.png" alt=""/>
                      </div>
                      <div className="content">
                          <div className="numb" data-from="0" data-to={durumVerileri['Lokasyon']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Lokasyon']}</div>
                          <div className="text">
                              Lokasyon Sayısı
                          </div>
                      </div>
                  </li>
                  <li className="progres">
                      <div className="icon">
                          <img src="images/icon/tick.png" alt=""/>
                      </div>
                      <div className="content">
                          <div className="numb" data-from="0" data-to={durumVerileri['Bolum']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Bolum']}</div>
                          <div className="text">
                              Bölüm Sayısı
                          </div>
                      </div>
                  </li>
                  <li className="progres">
                      <div className="icon">
                          <img src="images/icon/chart.png" alt=""/>
                      </div>
                      <div className="content">
                          <div className="numb" data-from="0" data-to={durumVerileri['Grup']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Grup']}</div>
                          <div className="text">
                              Grup sayısı
                          </div>
                      </div>
                  </li>
                  
              </ul>
          </div>
      </div>
      <div>
        <h3>Dashboard Data</h3>
        <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
 
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

            <DataTable columns={columns}
            data={tabloVerileri} 
            customStyles={customStyles}
            theme="solarized" // Burada theme'yi kullanın
            responsive="true"
            highlightOnHover
pointerOnHover
pagination
   />



      </div>
        </div>
        </div>
        </div>

       
      </div>
  
      <Mainpage/>
      {/* <pre>{JSON.stringify(tabloVerileri, null, 2)}</pre> */}
      </section>
</Container>
    );
  };
  
  export default Dashboard;
  