import { envIp } from '../Config/env';

export const login = async (username, password) => {
  try {
    const response = await fetch(`${envIp}users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyName: "UIB",
        userName: username,
        userPassword: password
      }),
    });

    const data = await response.json();
    if (data.status === "OK") {
      localStorage.setItem('userData', JSON.stringify(data));
      return true;
    } else {
      alert('Yanlış kullanıcı adı veya şifre!');
      return false;
    }
  } catch (error) {
    console.error('Login error:', error);
    alert('Bir hata oluştu!');
    return false;
  }
};
