import React, { useState,Fragment } from 'react';
import { useContext } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Header } from '../components/header';
import { Sidebar } from '../components/sidebar';
import { Container } from 'reactstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from './Footer';
import CustomizerContext from '../_helper/Customizer';

// import { RightBar } from '../components/rightbar';


const Layout = () => {
  const [isRightBarOpen, setIsRightBarOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleRightBar = () => setIsRightBarOpen(!isRightBarOpen);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <Fragment>
     
{/*        <Header toggleRightBar={toggleRightBar} toggleSidebar={toggleSidebar} /> 
   <p></p>

   <Sidebar className="sidebar-member fixed" isOpen={isSidebarOpen} />
 
  
  

   <main>
  
   <Outlet />  
      </main>
    
  
 
   
      <Footer /> */}
   
      <div className='body'>
      <Header toggleRightBar={toggleRightBar} toggleSidebar={toggleSidebar} /> 
        <div className='page-body-wrapper'>
        <Sidebar className="sidebar-member fixed" isOpen={isSidebarOpen} />
        
         
          <main>
          <div className='rows'>
         
          <Outlet />
          </div>
          <Footer />
          
          
          </main>
              
                   
                
           
        
        </div>
      </div>

      {/* {isRightBarOpen && <RightBar isOpen={isRightBarOpen} />} */}
    </Fragment>
  );
};

export default Layout;
