export const Inbox = () => {
    return (
        <div className="box">
        <div className="box-header with-border">
            <div className="box-title">
                <h3>INBOX</h3>
                <span>47</span>
            </div>

              <div className="clearfix"></div>
        </div>
        <div className="box-content">
      DENEME
      
        </div>
        <div className="clearfix"></div>
    </div>

    )
    }