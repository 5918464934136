import React, { useState, useEffect,useMemo,Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { envIp } from '../Config/env';
import { Breadcrumbs,Btn,Ribbon,P,H4 } from '../AbstractElements';
import Tabs from 'react-bootstrap/Tabs';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AssetsDemirbasTable from './Assets/AssetsDemirbasTables';
import './Assets/AssetImagesSlider.css';
import * as XLSX from 'xlsx';
import { Container } from 'reactstrap';

 const InventoryList = () => {
    const [loading, setLoading] = useState(true);

    const [tabloVerileri, setTabloVerileri] = useState([]);
    const [durumVerileri, setDurumVerileri] = useState({});

    const [tameslesenDemirbaslar, setTamEslesenDemirbaslar] = useState({});
    const [tameslesmeyenDemirbaslar, setTamEslesmeyenfDemirbaslar] = useState({});
    const [defterToplami, setDefterToplami] = useState({});
    const [tumDemirbaslar, setATumDemirbaslar] = useState({});
    const [bolumDemirbaslar, setBolumfDemirbaslar] = useState({});
    const [zimmetDemirbaslar, setZimmetDemirbaslar] = useState({});
    const [sabitkiymetDemirbaslar, setSabitKiymetanDemirbaslar] = useState({});
  
    const [searchInput, setSearchInput] = useState("");
    const [searchInputBolum, setSearchInputBolum] = useState("");
    const [searchInput1, setSearchInput1] = useState("");
  // API'den gelen orijinal veri üzerinde arama yaparak filtrelenmiş veriyi oluştur
  
  
  const exportToExcel = (data, fileName = "envanterler.xlsx") => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };
  
  const filteredData = useMemo(() => {
    if (!searchInput) return zimmetDemirbaslar;
  
    return zimmetDemirbaslar.filter(item =>
      Object.values(item).some(value =>
        value != null && value.toString().toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [zimmetDemirbaslar, searchInput]);
 
 
  const filteredDataEslesen = useMemo(() => {
    if (!searchInput1) return tameslesenDemirbaslar;
  
    return tameslesenDemirbaslar.filter(item =>
      Object.values(item).some(value =>
        value != null && value.toString().toLowerCase().includes(searchInput1.toLowerCase())
      )
    );
  }, [tameslesenDemirbaslar, searchInput1]);

  const filteredDataBolum = useMemo(() => {
    if (!searchInputBolum) return bolumDemirbaslar;
  
    return bolumDemirbaslar.filter(item =>
      Object.values(item).some(value =>
        value != null && value.toString().toLowerCase().includes(searchInputBolum.toLowerCase())
      )
    );
  }, [bolumDemirbaslar, searchInputBolum]);
  
  

    useEffect(() => {
      
      // API isteği ve veri işleme
      const fetchDashboardData = async () => {
        try {
          const storedUserData = localStorage.getItem('userData');
          const userData = storedUserData ? JSON.parse(storedUserData) : null;
          const token = userData ? userData.token : '';
  
          const response = await fetch(`${envIp}asset/getAssetDashBord`, {
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
            },
          });
  
          if (!response.ok) {
            throw new Error('Dashboard verisi yüklenirken bir hata oluştu!');
          }
  
          const data = await response.json();
          if (data.status === "Ok") {
            setTabloVerileri(data.data[0]);
            setDurumVerileri(data.data[1][0]);
           
          } else {
            throw new Error('Yanıt beklenen formatta değil.');
          }
        } catch (error) {
          console.error('Dashboard verisi yüklenirken bir hata meydana geldi:', error);
          toast.error('Dashboard verisi yüklenirken bir hata oluştu! ' + error.message);
        }
      };
  
      fetchDashboardData();
    }, []);

    useEffect(() => {
      setLoading(true);
      // API isteği ve veri işleme
      const fetchDashboardData1 = async () => {
        try {
          const storedUserData = localStorage.getItem('userData');
          const userData = storedUserData ? JSON.parse(storedUserData) : null;
          const token = userData ? userData.token : '';
  
          const response1 = await fetch(`${envIp}asset/getGroupedAssets`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token,
            },
          });
  
          if (!response1.ok) {
            throw new Error('Dashboard verisi yüklenirken bir hata oluştu!');
          }
  
          const data1 = await response1.json();
    if (data1.status === "Ok") {
      if (Array.isArray(data1.data[0])) {
        setTamEslesenDemirbaslar(data1.data[0]);
        setTamEslesmeyenfDemirbaslar(data1.data[2]);
        setDefterToplami(data1.data[4]);
        setATumDemirbaslar(data1.data[6]);
        setBolumfDemirbaslar(data1.data[8]);
        setZimmetDemirbaslar(data1.data[9]);
        setSabitKiymetanDemirbaslar(data1.data[10]);

        setLoading(false);
      } else {
        console.error('Beklenen dizi formatında değil:', data1.data[0]);
        setTamEslesenDemirbaslar([]); // Beklenen format dışında bir şey gelirse boş dizi ata
        setLoading(false);
      }
    } else {
      throw new Error('Yanıt beklenen formatta değil.');
    
    }
        } catch (error) {
          console.error('Dashboard verisi yüklenirken bir hata meydana geldi:', error);
          toast.error('Dashboard verisi yüklenirken bir hata oluştu! ' + error.message);
          setLoading(false);
        }
      };
  
      fetchDashboardData1();
    }, []);
   
    const columns = useMemo(() => [
      {
        name: 'DEMİRBAŞ KODU',
        accessor: 'DemirbasKodu', // Veri anahtarı
        selector: row => row.DemirbasKodu,
        sortable: true,
      },
      {
        name: 'LOKASYON',
        accessor: 'Lokasyon',
        selector: row => row.Lokasyon,
        sortable: true,
      },
      {
        name: 'BÖLÜM',
        accessor: 'Bolum',
        selector: row => row.Bolum,
        sortable: true,
      },
      {
        name: 'GRUP',
        accessor: 'Grup',
        selector: row => row.Grup,
        sortable: true,
      },
      {
        name: 'DEMİRBAS ADI',
        accessor: 'DemirbasAdı',
        sortable: true,
        selector: row => row.DemirbasAdı,
        cell: value => <Link to={`/asset/${value.DemirbasKodu}`}>{value.DemirbasAdı}</Link>,
      },
      // Diğer sütunlarınız...
    ], []);
  
    const handleRowClicked = row => {
      // 'BÖLÜM' değerini alıp arama girdisine ayarlayın
      const bolum = row.BÖLÜM;
      setSearchInput(bolum); // 'zimmetDemirbaslar' tablosunda arama yapmak için kullanılan state
    };
    const columnsBolum = useMemo(() => [

      {
        name: 'BÖLÜM',
        selector: row => row.BÖLÜM,
        sortable: true,
        cell: row => <span title={row.BÖLÜM} onClick={() => handleRowClicked(row)}>{row.BÖLÜM}</span>
      },
      {
        name: 'TOPLAM EŞLEŞEN BÖLÜM',
        accessor: 'eslesenbolum',
        selector: row => row["TOPLAM EŞLEŞEN BÖLÜM"],
        sortable: true,
      }
    
      // Diğer sütunlarınız...
    ], []);

    const columnsForTameslesenDemirbaslar = [
      {
        name: 'ID',
        selector: row => row.ID,
        sortable: true,
      },
      {
        name: 'BÖLÜM KODU',
        selector: row => row['BÖLÜM KODU'],
        sortable: true,
        cell: row => <span title={row['BÖLÜM KODU']}>{row['BÖLÜM KODU']}</span>
      },
     
      {
        name: 'DEMİRBAŞ KODU',
        selector: row => row['DEMİRBAŞ KODU'],
        sortable: true,
        cell: row => <Link to={`/asset/${row['DEMİRBAŞ KODU']}`}>{row['DEMİRBAŞ ADI']}</Link>,
      },
      {
        name: 'DEMİRBAŞ ADI',
        selector: row => row['DEMİRBAŞ ADI'],
        sortable: true,
        // Eğer DEMİRBAŞ ADI'nı bir link olarak göstermek isterseniz, cell özelliğini kullanabilirsiniz:
        cell: row => <Link to={`/asset/${row['DEMİRBAŞ KODU']}`}>{row['DEMİRBAŞ ADI']}</Link>,
      },
      {
        name: 'EŞYANIN CİNSİ, ŞEKLİ, VASIFLARI',
        selector: row => row['EŞYANIN CİNSİ,ŞEKLİ, VASIFLARI'],
        sortable: true,
        // Eğer bu alan boşsa gösterilmeyecekse aşağıdaki gibi bir kontrol ekleyebilirsiniz:
        cell: row => row['EŞYANIN CİNSİ,ŞEKLİ, VASIFLARI'] || 'Tanımsız',
      },
      {
        name: 'UIB SIRA NO',
        selector: row => row['UIB SIRA NO'] || '',
        sortable: true,
      },
      {
        name: 'UIB KAYIT NO',
        selector: row => row['UIB KAYIT NO'] || '',
        sortable: true,
      },
      {
        name: 'UIB TARİH',
        selector: row => row['UIB TARIH'] ? new Date(row['UIB TARIH']).toLocaleDateString() : '',
        sortable: true,
      },
      {
        name: 'UIB TUTAR',
        selector: row => row['UIB TUTAR'] || '',
        sortable: true,
        // Eğer tutar para birimi ile birlikte gösterilecekse formatlama ekleyebilirsiniz:
        cell: row => row['UIB TUTAR'] ? `${row['UIB TUTAR']} TL` : '',
      },
      // Diğer sütunlarınız...
    ]


    const columnsForTameslesenDemirbaslarZimmet = [
     
      {
        name: 'BÖLÜM KODU',
        selector: row => row['BÖLÜM KODU'],
        sortable: true,
        cell: row => <span title={row['BÖLÜM KODU']}>{row['BÖLÜM KODU']}</span>
      },
      {
        name: 'ZİMMET BİLGİSİ',
        selector: row => row['ZİMMET BİLGİSİ'],
        sortable: true,
        cell: row => <span title={row['ZİMMET BİLGİSİ']}>{row['ZİMMET BİLGİSİ']}</span>
      },
      {
        name: 'DEMİRBAŞ KODU',
        selector: row => row['DEMİRBAŞ KODU'],
        sortable: true,
        cell: row => <Link to={`/asset/${row['DEMİRBAŞ KODU']}`}>{row['DEMİRBAŞ ADI']}</Link>,
      },
      {
        name: 'DEMİRBAŞ ADI',
        selector: row => row['DEMİRBAŞ ADI'],
        sortable: true,
        // Eğer DEMİRBAŞ ADI'nı bir link olarak göstermek isterseniz, cell özelliğini kullanabilirsiniz:
        cell: row => <Link to={`/asset/${row['DEMİRBAŞ KODU']}`}>{row['DEMİRBAŞ ADI']}</Link>,
      },
      {
        name: 'EŞYANIN CİNSİ, ŞEKLİ, VASIFLARI',
        selector: row => row['EŞYANIN CİNSİ,ŞEKLİ, VASIFLARI'],
        sortable: true,
        // Eğer bu alan boşsa gösterilmeyecekse aşağıdaki gibi bir kontrol ekleyebilirsiniz:
        cell: row => row['EŞYANIN CİNSİ,ŞEKLİ, VASIFLARI'] || 'Tanımsız',
      },
      {
        name: 'UIB SIRA NO',
        selector: row => row['UIB SIRA NO'] || '',
        sortable: true,
      },
      {
        name: 'UIB KAYIT NO',
        selector: row => row['UIB KAYIT NO'] || '',
        sortable: true,
      },
      {
        name: 'UIB TARİH',
        selector: row => row['UIB TARIH'] ? new Date(row['UIB TARIH']).toLocaleDateString() : '',
        sortable: true,
      },
      {
        name: 'UIB TUTAR',
        selector: row => row['UIB TUTAR'] || '',
        sortable: true,
        // Eğer tutar para birimi ile birlikte gösterilecekse formatlama ekleyebilirsiniz:
        cell: row => row['UIB TUTAR'] ? `${row['UIB TUTAR']} TL` : '',
      },
      // Diğer sütunlarınız...
    ]
    const customStyles = {
      rows: {
          style: {
              minHeight: '72px', // override the row height
          },
      },
      headCells: {
          style: {
              paddingLeft: '8px', // override the cell padding for head cells
              paddingRight: '8px',
              color: '#FFFFFF',  
            backgroundColor: '#763B5C', 
           
          
          },
      },
      cells: {
          style: {
              paddingLeft: '8px', // override the cell padding for data cells
              paddingRight: '8px',
          },
      },
    };
    
  
    // React Table hooklarını kullanarak tablo instance'ını oluşturma
    // ...
  // useTable hook'unu çağırırken data olarak tabloVerileri'ni kullan


    return (

        <>
                 
               <div className="rows">
      

      <h1 className="text-3xl font-bold ">
      Inventory Page
      </h1>
     
                      </div>
        <div className="rows">
            <div className="status-bar">
                <ul>
                    <li className="progres">
                        <div className="icon">
                            <img src="images/icon/progress.png" alt=""/>
                        </div>
                        <div className="content">
                      <div className="numb" data-from="0" data-to={durumVerileri['TOPLAM DEMİRBAŞ']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['TOPLAM DEMİRBAŞ']}</div>
                            <div className="text">
                                TOPLAM
                            </div> 
  
                         
                        </div>
                    </li>
                    <li className="progres">
                        <div className="icon">
                            <img src="images/icon/task.png" alt=""/>
                        </div>
                        <div className="content">
                            <div className="numb" data-from="0" data-to={durumVerileri['Lokasyon']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Lokasyon']}</div>
                            <div className="text">
                                Lokasyon Sayısı
                            </div>
                        </div>
                    </li>
                    <li className="progres">
                        <div className="icon">
                            <img src="images/icon/tick.png" alt=""/>
                        </div>
                        <div className="content">
                            <div className="numb" data-from="0" data-to={durumVerileri['Bolum']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Bolum']}</div>
                            <div className="text">
                                Bölüm Sayısı
                            </div>
                        </div>
                    </li>
                    <li className="progres">
                        <div className="icon">
                            <img src="images/icon/chart.png" alt=""/>
                        </div>
                        <div className="content">
                            <div className="numb" data-from="0" data-to={durumVerileri['Grup']} data-speed="1000" data-waypoint-active="yes">{durumVerileri['Grup']}</div>
                            <div className="text">
                                Grup sayısı
                            </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
        </div>

        <div className="rows">
          <h3>INVENTORYS Data</h3>         {/* Sayfalama kontrolleri */}
          <br/>
          <Row>
        <Col lg={true}  className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            
     
        
      
        <div className="box-search">
        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>ZİMMET LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(filteredData, 'zimmetListesi.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
                <form action="#" method="get" acceptCharset="utf-8">
              
                  <input type="text" placeholder="Arama yap..." name="Search"  value={searchInput}
        onChange={e => setSearchInput(e.target.value)}></input>

                </form>
            </div> 
            <br/>
            {loading ? (
        <div>Loading...</div> // Burada bir yükleme animasyonu veya gösterge kullanabilirsiniz
      ) : (
        <div>
              <div className="status-bar">
        <DataTable columns={columnsForTameslesenDemirbaslarZimmet}
            data={filteredData || []} 
            customStyles={customStyles}
            theme="solarized" // Burada theme'yi kullanın
            responsive="true"
            highlightOnHover
pointerOnHover
pagination
   /> 

        </div>

        </div>
      )}
       
          
        </Col>
        <Col sm={4}><div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
             
        {loading ? (
        <div>Loading...</div> // Burada bir yükleme animasyonu veya gösterge kullanabilirsiniz
      ) : (
        <div>
        <div className="box-search">
        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>BÖLÜM LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(filteredDataBolum, 'bolumListesi.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
                <form action="#" method="get" acceptCharset="utf-8">
              
                  <input type="text" placeholder="Arama yap..." name="Search"  value={searchInputBolum}
        onChange={e => setSearchInputBolum(e.target.value)}></input>

                </form>
            </div> 


            <br/>
         
            <DataTable columns={columnsBolum}
            data={filteredDataBolum || []} 
            customStyles={customStyles}
            theme="solarized" // Burada theme'yi kullanın
            responsive="true"
            highlightOnHover
pointerOnHover
pagination
   /> 


       

        </div>
      )}
             </div></Col>
      </Row>
       <br/>
      <Row>
        <Col lg={true}  className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            
     
        
      

              <div className="status-bar">



              {loading ? (
        <div>Loading...</div> // Burada bir yükleme animasyonu veya gösterge kullanabilirsiniz
      ) : (
        <div>

<Tabs
      defaultActiveKey="envListesi"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="envListesi" title="ENVANTER LİSTESİ">
      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>ENVANTER LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(tabloVerileri, 'envanterListesi.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
            <AssetsDemirbasTable data={tabloVerileri} loading={loading} columns={columns} />
          
      </Tab>
      <Tab eventKey="dmrListesi" title="TAM EŞLEŞEN DEMİRBAŞLAR">

      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>TAM EŞLEŞEN DEMİRBAŞLAR LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(tameslesenDemirbaslar, 'tameslesenDemirbaslar.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
      <AssetsDemirbasTable 
        data={tameslesenDemirbaslar} 
        loading={loading} 
        columns={columnsForTameslesenDemirbaslar} 
      />

      </Tab>
      <Tab eventKey="dmrEslesmeyen" title="EŞLEŞMEYEN DEMİRBAŞLAR">
      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>EŞLEŞMEYEN DEMİRBAŞLAR LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(tameslesmeyenDemirbaslar, 'EŞLEŞMEYENDemirbaslar.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
      <AssetsDemirbasTable data={tameslesmeyenDemirbaslar} loading={loading} columns={columnsForTameslesenDemirbaslar} />
      </Tab>
      <Tab eventKey="dftr" title="DEFTER TOPLAMI">
      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>DEFTER LİSTESİ</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(defterToplami, 'DEFTER.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
      <AssetsDemirbasTable data={defterToplami} loading={loading} columns={columnsForTameslesenDemirbaslar} />
      </Tab>
      <Tab eventKey="dmr" title="DEMİRBAŞLAR">
      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
    <H4 attrH4={{ className: 'text m-0' }}>DEMİRBAŞLAR</H4>
    <div>
      <Btn attrBtn={{ color: 'success', className: 'mr-1', onClick: () => exportToExcel(tumDemirbaslar, 'DEMİRBAŞLAR.xlsx') }}>EXCEL AL</Btn>
     
    </div>
  </div>
      <AssetsDemirbasTable data={tumDemirbaslar} loading={loading} columns={columnsForTameslesenDemirbaslar} />
      </Tab>
    </Tabs>


       
       
  
  
        </div>
      )}

        </div>


       
          
        </Col>
        <Col sm={4}><div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Zimmet JSON">
      <pre>{JSON.stringify(zimmetDemirbaslar, null, 2)}</pre> 
      </Tab>
      <Tab eventKey="profile" title="Kıymet JSON">
      <pre>{JSON.stringify(sabitkiymetDemirbaslar, null, 2)}</pre> 
      </Tab>
      <Tab eventKey="contact" title="Bölüm JSON" >
      <pre>{JSON.stringify(bolumDemirbaslar, null, 2)}</pre>
      </Tab>
    </Tabs>
       
        {/* <pre>{JSON.stringify(tameslesmeyenDemirbaslar, null, 2)}</pre>
        <pre>{JSON.stringify(defterToplami, null, 2)}</pre>
        <pre>{JSON.stringify(tumDemirbaslar, null, 2)}</pre>
        <pre>{JSON.stringify(bolumDemirbaslar, null, 2)}</pre>
        <pre>{JSON.stringify(sabitkiymetDemirbaslar, null, 2)}</pre>
 */}

                          </div></Col>
      </Row>
       
     


        </div>


</>

    );
    }
    export default InventoryList;