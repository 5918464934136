export const BoxBox = () => {
    return (
        <div className="rows">
        <div className="box box-project left">
            <div className="box-header with-border">
                <div className="box-title">
                    <h3>SAYIM DURUMU</h3>
                    <span>6200</span>
                </div>
                  
                  <div className="clearfix"></div>
            </div>
            <div className="box-content">
                <table className="">
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>NAME</th>
                            <th>COUTING</th>
                            <th>PROGRESS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="developing">
                            <td>1</td>
                            <td>A BLOK</td>
                            <td className="bg"><span data-percent="240" data-waypoint-active="yes">Devam Ediyor</span></td>
                            <td>Deavm Ediyor</td>
                        </tr>
                        <tr className="design">
                            <td>2</td>
                            <td>B BLOK</td>
                            <td className="bg"><span data-percent="10" data-waypoint-active="yes">Tamamlandı</span></td>
                            <td>Tamamlandı</td>
                        </tr>
                        <tr className="cancel">
                            <td>3</td>
                            <td>C BLOK</td>
                            <td className="bg"><span data-percent="160" data-waypoint-active="yes">İptal</span></td>
                            <td>İptal</td>
                        </tr>
                        <tr className="testing">
                            <td>4</td>
                            <td>D BLOK</td>
                            <td className="bg"><span data-percent="10" data-waypoint-active="yes">Tamamlandı</span></td>
                            <td>Tamamlandı</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="box box-inbox right">
            <div className="box-header with-border">
                <div className="box-title">
                    <h3>Personel</h3>
                    <span>6</span>
                </div>
                  <div className="box-tools pull-right">
                      <i className="zmdi zmdi-more-vert waves-effect waves-teal"></i>
                      <ul className="dropdown-menu">
                          <li>
                              <a href="#" className="waves-effect" title="">Action</a>
                          </li>
                          <li>
                              <a href="#" className="waves-effect" title="">Support</a>
                          </li>
                          <li>
                              <a href="#" className="waves-effect" title="">FAQ</a>
                          </li>
                          <li>
                              <a href="#" className="waves-effect" title="">Message</a>
                          </li>
                      </ul>
                  </div>
                  <div className="clearfix"></div>
            </div>
            <div className="box-content">
                <ul className="inbox-list">
                    <li className="waves-effect">
                        <a href="#" title="">
                            <div className="left">
                                <img src="images/avatar/inbox-01.png" alt=""/>
                                <div className="info">
                                    <p className="name">Ender Öztürk</p>
                                    <p>D Blok sayımı yapıyor</p>
                                </div>
                            </div>
                            <div className="right">
                                Toplam : 236
                            </div>
                            <div className="clearfix"></div>
                        </a>
                    </li>	
                    <li className="waves-effect">
                        <a href="#" title="">
                            <div className="left">
                                <img src="images/avatar/inbox-02.png" alt=""/>
                                <div className="info">
                                    <p className="name">Khaled Msouiti</p>
                                    <p>D Blok sayımı yapıyor</p>
                                </div>
                            </div>
                            <div className="right">
                                Toplam : 643
                            </div>
                            <div className="clearfix"></div>
                        </a>
                    </li>	
                    <li className="waves-effect">
                        <a href="#" title="">
                            <div className="left">
                                <img src="images/avatar/inbox-03.png" alt=""/>
                                <div className="info">
                                    <p className="name">Onur Yıldırım</p>
                                    <p>D Blok sayımı yapıyor</p>
                                </div>
                            </div>
                            <div className="right">
                            Toplam : 799
                            </div>
                            <div className="clearfix"></div>
                        </a>	
                    </li>
                    <li className="waves-effect">
                        <a href="#" title="">
                            <div className="left">
                                <img src="images/avatar/inbox-04.png" alt=""/>
                                <div className="info">
                                    <p className="name">John Alex</p>
                                    <p>D Blok sayımı yapıyor</p>
                                </div>
                            </div>
                            <div className="right">
                            Toplam : 648
                            </div>
                            <div className="clearfix"></div>
                        </a>	
                    </li>
                </ul>
            </div>
        </div>
        <div className="clearfix"></div>
       
        <div className="clearfix"></div>
    </div>


    )
}