import React from 'react';

import { Container } from 'react-bootstrap';
 const Messages = () => {

    return (
      <Container>
        <div  className="rows">
        <h1>MESASGES - soso Modules</h1>
        {/* Other dashboard content */}
          
       
       
    {/* <GetAllUsers key={refreshUsers}/> */}
  

    <div className="box box right">
      
        <div className="box-content">
        You are not yet authorized for this page
      {/*   <CreateUser onUserCreated={() => setRefreshUsers(prev => !prev)}/> */}
        </div>
        <div className="clearfix"></div>
      
    </div>
  
  

    </div>
</Container>


    );
    }
    export default Messages;