import React from 'react';
import { Link, useLocation } from 'react-router-dom';
export const Sidebar = ({ isOpen }) => {
    const location = useLocation();

    // Function to determine if the link is active based on the current location
    const isActive = (path) => location.pathname === path;

    return (
        <section className={`vertical-navigation left ${isOpen ? 'active' : ''}`}>
       
        <ul className="sidebar-nav">
            <li className="message waves-effect waves-teal">
            
            <h2>portal</h2>
           
 
                </li>
            <li className={`dashboard waves-effect waves-teal ${isActive('/') ? 'active' : ''}`}>
                <Link to="/">
                    <div className="img-nav">
                        <img src="images/icon/monitor.png" alt=""/>
                    </div>
                    <span>DASHBOARD</span>
                </Link>
            </li>
            <li className={`pages waves-effect waves-teal ${isActive('/inventory-list') ? 'active' : ''}`}>
                <Link to="/inventory-list">
                    <div className="img-nav">
                        <img src="images/icon/pages.png" alt=""/>
                    </div>
                    <span>INVENTORY LIST</span>
                </Link>
            </li>
            <li className={`apps waves-effect waves-teal ${isActive('/apps') ? 'active' : ''}`}>
                <Link to="/apps">
                    <div className="img-nav">
                        <img src="images/icon/apps.png" alt=""/>
                    </div>
                    <span>APPS</span>
                </Link>
            </li>
            <li className={`message waves-effect waves-teal ${isActive('/messages') ? 'active' : ''}`}>
    <Link to="/messages" className="img-nav-link">
        <div className="img-nav">
            <img src="images/icon/message.png" alt="Message Icon"/>
            <span className="notification-badge">3</span>
        </div>
        <span>MESSAGE</span>
    </Link>
</li>


            <li className={`settings waves-effect waves-teal ${isActive('/settings') ? 'active' : ''}`}>
            <Link to="/settings">
                <div className="img-nav">
                    <img src="images/icon/setting-02.png" alt=""/>
                </div>
                </Link>
                <Link to="/settings">
                <span>SETTING</span>
                </Link>
            </li>
        </ul>
    </section>

    );
    }
