import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';
import { envIp } from '../Config/env';

export const UpdateUser = () => {
    const { userName } = useParams();
    
    const [userDetails, setUserDetails] = useState({
        userName: '',
        password: '',
        firstName: '',
        lastName: '',
        role: '',
        // Diğer alanlar...
    });
    const navigate = useNavigate();
  
    
    useEffect(() => {
        fetchUserDetails(userName);
    }, [userName]);

    const fetchUserDetails = async (userName) => {
        try {
            const storedUserData = localStorage.getItem('userData');
            const userData = storedUserData ? JSON.parse(storedUserData) : null;
            const token = userData ? userData.token : '';

            const response = await fetch(`${envIp}users/getUser?userName=${encodeURIComponent(userName)}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'x-access-token': token
                }
            });

            const result = await response.json();
            if (result.status === "OK") {
                setUserDetails({
                  ...userDetails,
                  userName: result.data.UserName,
                  firstName: result.data.FirstName,
                  lastName: result.data.LastName,
                  role: result.data.Role,
                  // Diğer alanlarınız...
                });
              } else {
                toast.error('Kullanıcı detayları yüklenirken hata oluştu.');
              }
        } catch (error) {
            toast.error('Kullanıcı detayları yüklenirken bir hata oluştu!');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(userDetails);
        try {
            const storedUserData = localStorage.getItem('userData');
            const userData = storedUserData ? JSON.parse(storedUserData) : null;
            const token = userData ? userData.token : '';

            const response = await fetch(`${envIp}users/updateUser`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token
                },
                body: JSON.stringify(userDetails)
            });

            const result = await response.json();
            if (result.status === "OK") {
                toast.success('Kullanıcı başarıyla güncellendi.');
                navigate('/users'); // Kullanıcı listesi sayfasına geri dön
            } else {
                toast.error('Kullanıcı güncellenirken hata oluştu.');
            }
            console.log(result);
        } catch (error) {
            toast.error('Kullanıcı güncellenirken bir hata oluştu!');
        }
    };

    return (
        <div className="update-user-page">
     
            <h2>Kullanıcı Güncelle</h2>
            <Form onSubmit={handleSubmit}>
            <Form.Control
  type="text"
  name="firstName"
  value={userDetails.firstName || ''}
  onChange={handleChange}
/>
<Form.Control
  type="text"
  name="lastName"
  value={userDetails.lastName || ''}
  onChange={handleChange}
/>
    {/* Diğer form alanları... */}
    <Button variant="primary" type="submit">
        Güncelle
    </Button>
</Form>
        </div>
    );
};
export default UpdateUser;
