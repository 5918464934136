import React, { useState, useEffect,useMemo } from 'react';
import {  useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssetImagesSlider from './AssetImagesSlider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { envIp } from '../../Config/env';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container,Card, CardBody, CardFooter, CardGroup, CardHeader, CardImg, CardImgOverlay, CardLink, CardSubtitle, CardText, CardTitle } from 'react-bootstrap';
import './AssetImagesSlider.css';

const AssetPageItem = () => {

  const [loading, setLoading] = useState(true);

  const { assetName } = useParams();

  const [assetsDetails, setAssetsDetails] = useState({});
  const [demirbasDetails, setDemirbasDetails] = useState([]); // Boş dizi olarak başlatın
  
  const [filesArray, setFilesArray] = useState([]);
useEffect(() => {
  fetchUserDetails(assetName);
}, [assetName]);

const fetchUserDetails = async (assetName) => {
  try {
      const storedUserData = localStorage.getItem('userData');
      const userData = storedUserData ? JSON.parse(storedUserData) : null;
      const token = userData ? userData.token : '';

      const response = await fetch(`${envIp}asset/getAssetByCode?assetCode=${encodeURIComponent(assetName)}`, {
          method: 'GET',
          headers: {
              'accept': 'application/json',
              'x-access-token': token
          }
      });

     
      const result = await response.json();

      if (result.status === "OK") {
        setDemirbasDetails([result.data[0]]); // API'dan gelen veriyi diziye çevirin
        setAssetsDetails(result.data[0]);
        toast.success(`Asset detayları yüklendi: ${result.data[0].AssetCode}`);


        
        try {
          // Files alanını JSON olarak parse edin ve bir diziye dönüştürün
          setFilesArray(JSON.parse(result.data[0].Files));
          console.log(filesArray)
          setLoading(false);
        } catch (error) {
          toast.error(`JSON parse hatası${error.message}`);
          filesArray = []; // Parse sırasında bir hata oluşursa boş bir dizi kullanın
        }

      } else {
        throw new Error('Yanıt beklenen formatta değil.');
      }
    } catch (error) {
      toast.error(`Asset detayları yüklenirken bir hata oluştu! ${error.message}`);
    }


};





 




  // assetName ile ilgili işlemler
 
  return (
    <Container>
      


      {loading ? (
        <div>Loading...</div> // Burada bir yükleme animasyonu veya gösterge kullanabilirsiniz
      ) : (
        <div>
  
   
   <div className="container">
    <Card>   
      <CardHeader className='dark-theme'> 
      <h1 className="title">{assetsDetails?.AssetName || "Asset Name"}</h1>  <span>{assetsDetails?.Id}</span>
      </CardHeader>
      <CardBody  className="dark-theme">
      <Row  className="dark-theme">
      <Col className="shadow overflow-hidden border-b border-gray-100 sm:rounded-lg">
<AssetImagesSlider images={filesArray.map(file => file.Photo)} />
</Col>
<br/>
      <Col className="shadow overflow-hidden border-b border-gray-100 sm:rounded-lg">

      <div className='inbox-list'>
        
        <p className="product-description">
        <h3>TANIM </h3>   {assetsDetails?.ProductCode || "Product description will be shown here."}
        </p>
        <br/>
        <div>
        <h3>ENVANTER KODU</h3> <p className="price">{assetsDetails?.AssetCode || "......"}</p>
        </div>
        <br/>
        <p><h4>Muhasebe Kodu </h4> {assetsDetails?.CurrentCode || "........."}</p>
      </div>
     
 
  
</Col>


</Row>
 
      </CardBody>
      <CardFooter>
      <div>
        <h3>DEMİRBAŞ KODU</h3> <p className="price">{assetsDetails?.DemirbasKodu || "......"}</p>
        </div>
        <br/>
        <h3>DEMİRBAŞ ID</h3> <p className="price">{assetsDetails?.DemirbasId || "......"}</p>
       
        <br/>
        
        <p><h3>Seri No </h3> {assetsDetails?.SerialNumber || "........."}</p>

        <br/>
        {assetsDetails?.Descriptions || "........."}
        </CardFooter>
      </Card>
    </div>

    </div>
      )}

    </Container>
  );
};
export default AssetPageItem;
