import React, { useState } from 'react';
 import { CreateUser } from '../Auth/CreateUser';
 import { GetAllUsers } from '../Auth/GetAllUsers';
import { Container } from 'react-bootstrap';

 const Settings = () => {
  const [refreshUsers, setRefreshUsers] = useState(false);
    return (
      <Container>
          
        <div  className="rows">
        <h1>SETTINGS PAGE</h1>
       
    <GetAllUsers key={refreshUsers}/>
  
    <div className="box box right">
      
        <div className="box-content">
   
        <CreateUser onUserCreated={() => setRefreshUsers(prev => !prev)}/>
        </div>
        <div className="clearfix"></div>
      
    </div>
  
  

    </div>
</Container>


    );
    }
    export default Settings;