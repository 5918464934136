import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

const AssetsDemirbasTable = ({ data, loading, columns }) => {
    const [filterText, setFilterText] = useState('');
  
    const filteredData = useMemo(() => {
        return data.filter(item => {
          return Object.keys(item).some(key => {
            const value = item[key];
            return value != null && value.toString().toLowerCase().includes(filterText.toLowerCase());
          });
        });
      }, [data, filterText]);
      
  

  const customStyles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            color: '#FFFFFF',  
          backgroundColor: '#763B5C', 
         
        
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
        },
    },
  };

  return (
    <>
    <br />
    <input
      type="text"
      placeholder="Arama yap..."
      value={filterText}
      onChange={e => setFilterText(e.target.value)}
    />
    <br />
    <p></p>
    <br />
    <DataTable
      columns={columns}
      data={filteredData}
      customStyles={customStyles}
      theme="solarized"
      responsive={true}
      highlightOnHover={true}
      pointerOnHover={true}
      pagination={true}
      progressPending={loading}
    />
  </>
  );
};

export default AssetsDemirbasTable;
