import React, { useState, useEffect } from 'react';
import { envIp } from '../Config/env';
import { toast } from 'react-toastify';
import {  Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


export const GetAllUsers = () => {
    const [users, setUsers] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => {
      setModalVisible(true);
    };
  
    // Modalı kapatma fonksiyonu
    const closeModal = () => {
      setModalVisible(false);
    };
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const storedUserData = localStorage.getItem('userData');
            const userData = storedUserData ? JSON.parse(storedUserData) : null;
            const token = userData ? userData.token : '';

            const response = await fetch(`${envIp}users/getAllUsers?status=a`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'x-access-token': token
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status === "OK" && Array.isArray(result.data)) { 
                setUsers(result.data); // Kullanıcıları "data" dizisinden alıyoruz
            } else {
                throw new Error('Yanıt beklenen formatın dışında.');
            }
        } catch (error) {
            console.error('Kullanıcıları yüklerken bir hata oluştu:', error);
            toast.error('Kullanıcıları yüklerken bir hata oluştu!');
        }
    };

    const handleUserClick = (userName) => {
      fetchUserDetails(userName);
      toast.success(userName + ' Kullanıcısı seçildi!')
     //   navigate(`/updateUser/${encodeURIComponent(userName)}`);
    };


    const [userDetails, setSelectedUser] = useState({
      UserName: '',
      Id: '',
      firstName: '',
      lastName: '',
      role: '',
      company:'',
      password:'',
                messageToken: '', // Burada messageToken için boş string tanımlıyoruz
                branch: ''
      // Diğer alanlar...
  });


    const fetchUserDetails = async (userName) => {
      // Token alımı ve API isteği...
      try {
        const storedUserData = localStorage.getItem('userData');
        const userData = storedUserData ? JSON.parse(storedUserData) : null;
        const token = userData ? userData.token : '';
          const response = await fetch(`${envIp}users/getUser?userName=${encodeURIComponent(userName)}`, {
              method: 'GET',
              headers: {
                  'accept': 'application/json',
                  'x-access-token': token
              }
          });
          const result = await response.json();
          if (result.status === "OK") {
            setSelectedUser({
                ...userDetails,
                UserName: result.data.UserName,
                firstName: result.data.FirstName,
                lastName: result.data.LastName,
                Id: result.data.Id,
                role: result.data.Role,
                ProfilePictureLink: result.data.ProfilePictureLink,
                company: result.data.Company,
                messageToken: '', // Burada messageToken için boş string tanımlıyoruz
                branch: ''
                // Diğer alanlarınız...   
              });
              openModal(true);
            } else {
              toast.error('Kullanıcı detayları yüklenirken hata oluştu.' );
            }
        
      } catch (error) {
          toast.error('Kullanıcı detayları yüklenirken bir hata oluştu!');
      }
  };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser(prevDetails => ({
        ...prevDetails,
        [name]: value
    }));
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const token = userData ? userData.token : '';

    const payload = {
      id: userDetails.Id,
      userName: userDetails.UserName,
      password: userDetails.password, // Eğer şifre güncellenmeyecekse bu alanı çıkarabilirsiniz.
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      messageToken: '', // messageToken için boş string
      branch: '', // branch için boş string
      company: userDetails.company,
      role: userDetails.role
    };

    const response = await fetch(`${envIp}users/updateUser`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();
    if (result.status === "OK") {
      toast.success('Kullanıcı başarıyla güncellendi.');
      closeModal();
      fetchUsers(); // Kullanıcı listesini yeniden yükle
    } else {
      toast.error('Kullanıcı güncellenirken hata oluştu.');
    }
  } catch (error) {
    toast.error('Kullanıcı güncellenirken bir hata oluştu!');
  }
};


const [currentPage, setCurrentPage] = useState(1);
const [usersPerPage] = useState(10); // Her sayfada gösterilecek kullanıcı sayısı
const indexOfLastUser = currentPage * usersPerPage;
const indexOfFirstUser = indexOfLastUser - usersPerPage;
const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);


const pageNumbers = [];
for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
  pageNumbers.push(i);
}

const renderPageNumbers = pageNumbers.map(number => {
  return (
    <li
      key={number}
      id={number}
      onClick={() => setCurrentPage(number)}
      className={currentPage === number ? 'active' : null}
    >
      {number}
    </li>
  );
});

const [search, setSearch] = useState('');

const filteredUsers = users.filter(user =>
  user.FirstName.toLowerCase().includes(search.toLowerCase()) ||
  user.LastName.toLowerCase().includes(search.toLowerCase())
);

const currentFilteredUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    return (
        <>
     
       <div className="box box-kullanici left">
        <div className="box-header with-border">
            <div className="box-title">
                <h2>Kullanıcı Listesi <span> {users.length} </span> </h2>
                <br/>
                <Form.Control
  type="text"
  placeholder="Kullanıcı ara..."
  value={search}
  onChange={e => setSearch(e.target.value)}
/>

            </div>
         
                  <div className="clearfix"></div>
        </div>
          <div className="box-content">
            <ul className="kullanici-list">
            {currentFilteredUsers.map((user, index) => (
                /*  <li key={index} className="waves-effect" onClick={() => handleUserClick(user.UserName)}> */
                     <li key={index} className="waves-effect" onClick={() => handleUserClick(user.UserName)}>
                        <a href="#" title="">
                            <div className="left">
                                <img 
                                    src={user.ProfilePictureLink ? `http://sosodev.oetech.org/uploads/${user.ProfilePictureLink}` : 'path_to_default_image'} 
                                    alt="Profil Resmi"
                                    style={{ width: '50px', height: '50px' }} 
                                />
                                <div className="info">
                                    <p className="name">{user.FirstName} {user.LastName} </p>
                                    <p>{user.UserName} </p>
                                </div>
                            </div>
                            <div className="right">  <p><span>{user.Role}</span></p>
                            <p> ID :  <span>{user.Id}</span></p>
                            </div>
                            <div className="clearfix"></div>
                        </a>
                    </li>
                ))}
            </ul>
            <ul id="page-numbers">
  {renderPageNumbers}
</ul>
        </div>
       
        <div className="clearfix"></div>

</div>

      {modalVisible && (
  <div className="modal active" onClick={closeModal}> {/* Modalın dışına tıkladığında kapat */}
  <div className="modal-content" onClick={e => e.stopPropagation()}> {/* Modal içeriğinin tıklama olayını durdur */}
   
           
            <h4>Kullanıcı Güncelle <span className="close-button" onClick={closeModal}>&times;</span></h4>
          
             <br/>
              
             {userDetails && (
                        <Form onSubmit={handleSubmit}>

<Form.Group>  
<img 
                                    src={userDetails.ProfilePictureLink ? `http://sosodev.oetech.org/uploads/${userDetails.ProfilePictureLink}` : 'path_to_default_image'} 
                                    alt="Profil Resmi"
                                    style={{ width: '50px', height: '50px' }} 
                                />
  </Form.Group>

                                                     <Form.Group>
                                <Form.Label>ID</Form.Label>
                                <Form.Control type="label"
name="Id" readOnly
value={userDetails.Id || ''}
onChange={handleChange} />
                            </Form.Group>
                            <br/>
                            <Form.Group>
                                <Form.Label>Ad</Form.Label>
                                <Form.Control
                                type="text"
                                name="firstName"
                                value={userDetails.firstName || ''}
                                onChange={handleChange}/>
                            </Form.Group>
                            <br/>
                            <Form.Group>
                                <Form.Label>Soyad</Form.Label>
                                <Form.Control type="text"
name="lastName"
value={userDetails.lastName || ''}
onChange={handleChange} />
                            </Form.Group>
                            <br/>
                         



                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Form.Control type="text"
name="role"
value={userDetails.role || ''}
onChange={handleChange} />
                            </Form.Group>
                            <br/>
                            <Button variant="primary" type="submit">
        Güncelle
    </Button>
                        </Form>
                    )}
         
        
          </div>
          </div>
         
      )}
 </>
    );
};
export default GetAllUsers;