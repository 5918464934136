import { Inbox } from "./inbox"
import { BoxBox } from "./bosBox"   

export const Mainpage = () => {
  return(



<div className="rows">

<BoxBox />



<div className="clearfix"></div>
   </div>
        



)  

}


