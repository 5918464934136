import React, { useState, useEffect } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';

export const Header = ({ toggleRightBar, toggleSidebar }) => {
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const parsedData = JSON.parse(storedUserData);
            setUserData(parsedData.data); // "data" anahtarına erişim
        }
    }, []);
    
    const profileImageUrl = userData.ProfilePictureLink 
    ? `http://sosodev.oetech.org/uploads/${userData.ProfilePictureLink}`
    : 'images/default-avatar.png';

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
const handleLogout = () => {
      localStorage.removeItem('isAuthenticated'); // Oturum bilgisini kaldırıyoruz.
      navigate('/signin'); // Kullanıcıyı giriş sayfasına yönlendiriyoruz.
    };

    return (

    <header id="header" className="header fixed">
        <div className="navbar-top">
            <div className="curren-menu info-left">
                <div className="logo">
                    <a href="index.html" title="">
                        <img src="images/logo.png" alt="sOsO Admin"/>
                    </a>
                </div>
              {/*   <div className="top-button" onClick={toggleSidebar}>
                    <span></span>
                </div> */}
                <div className="box-search">
                
                    <form action="#" method="get" acceptCharset="utf-8">
                    <button className='primary'><img src="images/icon/search.png" alt=""/></button>
                        <input type="text" placeholder="Search Any..." name="Search"></input>
                    </form>
                </div>
            </div>
            <ul className="info-right">
                <li className="setting">
                <Link to="/settings">
                <img src="images/icon/setting.png" alt=""/>
          
              </Link>
                 
                </li>
                <li className="notification">
                    <a href="#" className="waves-effect waves-teal" title="">
                        7
                    </a>
                </li>
                <li className={`user ${isDropdownOpen ? 'open' : ''}`}>
                    <div className="avatar" onClick={toggleDropdown}>
                    <img src={profileImageUrl} alt=""/>
                    </div>
                    <div className="info">
                     <p className="FirstName">{userData.FirstName || 'Ad'}</p>
                        <p className="LastName">{userData.LastName || 'Soyad'}</p>
                        <p className="Role">{userData.Role || 'Rol'}</p>
             
                    </div>
                    <div className="arrow-down" onClick={toggleDropdown}>
                    <i className={`fa ${isDropdownOpen ? 'fa-angle-up' : 'fa-angle-down'}`} aria-hidden="true"></i>
                </div>
                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        <ul>
                            <li>
                                <div className="avatar">
                                <img src={profileImageUrl} alt=""/>
                                </div>
                              
                                <div className="clearfix"></div>
                            </li>
                            <li>
                                <a href="#" className="waves-effect" title="">My Account</a>
                            </li>
                            <li>
                            <Link to="/settings">
              
                <span>Setting</span>
                </Link>
       
                              
                            </li>
                            <li>
        <a href="#" className="waves-effect" title="" onClick={handleLogout}>Logout</a>
      </li>
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                </li>
              {/*   <li className="button-menu-right" onClick={toggleRightBar}>
                    <img src="images/icon/menu-right.png" alt=""/>
                </li> */}
            </ul>
            <div className="clearfix"></div>
        </div>
    </header>

  )
}