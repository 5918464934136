import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Signin from './Auth/Signin';
import Layout from './Layout/Layout';
import Dashboard from './Screens/Dashboard';
import InventoryList from './Screens/InventoryList';
import AppPages from './Screens/AppPages';
import Messages from './Screens/Messages';

import Settings from './Screens/Settings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GetAllUsers from '../src/Auth/GetAllUsers';
import UpdateUser from '../src/Auth/UpdateUser';
import AssetPageItem from './Screens/Assets/AssetPageItem';
/* The following line can be included in your src/index.js or App.js file */
import DataTable, { createTheme } from 'react-data-table-component';

// Yeni bir tema oluşturun
createTheme('solarized', {
  text: {
    primary: '#A1A1AA',
    secondary: '#763B5C',
  },
  background: {
    default: '#27272A',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#18181B',
  },
  button: {
    default: '#763B5C',
    hover: '#695D5E',
    focus: '#695D5E',
    disabled: 'rgba(255, 255, 255, .34)',
  },
  sortFocus: {
    default: '#763B5C',
  },
}, 'dark');
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticated = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authenticated);
  }, []);

  return (
    <>
<createTheme/>
    <ToastContainer className="my-toast-container" />
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Layout /> : <Navigate to="/signin" />}>
          {/* Layout altındaki rotalar buraya eklenir */}
          <Route path="/users" element={<GetAllUsers />} />
        <Route path="/updateUser/:userName" element={<UpdateUser />} />

          <Route index element={<Dashboard />} />
          <Route path="inventory-list" element={<InventoryList />} />
          <Route path="apps" element={<AppPages />} />
          <Route path="messages" element={<Messages />} />
          <Route path="settings" element={<Settings />} />
          <Route path="/asset/:assetName" element={<AssetPageItem />} />
          {/* ... Diğer alt rotalarınız ... */}
        </Route>
        <Route path="/signin" element={<Signin setIsAuthenticated={setIsAuthenticated} />} />
        {/* ... Diğer rotalarınız ... */}
      </Routes>
    </Router>
    </>
  );
}

export default App;
