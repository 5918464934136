import React, { useState } from 'react';
import { envIp } from '../Config/env';
import { toast } from 'react-toastify';
import  "./SignIn.css";
const CreateUser = ({ onUserCreated }) => {
    const [formData, setFormData] = useState({
        
        userName: '@uib.org.tr',
        password: 'uib112233',
        firstName: '',
        lastName: '',
         role: 'KULLANICI'
    });

    const handleChange = (e) => {
             setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const storedUserData = localStorage.getItem('userData');
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const company = userData ? userData.company : '';
    const token = userData ? userData.token : '';
    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...formData,
            companyName: company,
            company:company,
            messageToken: '', // Burada messageToken için boş string tanımlıyoruz
            branch: '' // Burada branch için boş string tanımlıyoruz
        };

        try {
            const response = await fetch(`${envIp}users/createUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token  // Token burada ekleniyor
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                onUserCreated();
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log(result);
            toast.success('Kullanıcı başarıyla oluşturuldu!');
        } catch (error) {
            console.error('Kullanıcı oluşturulurken bir hata oluştu:', error);
            toast.error('Kullanıcı oluşturulurken bir hata oluştu!');
            alert('Kullanıcı oluşturulurken bir hata oluştu!');
        }
    };

    return (
        <div>
            <h2>Kullanıcı Oluştur</h2>
            <form onSubmit={handleSubmit}>
                {Object.keys(formData).map(key => (
                    <div key={key}>
                         <br/>
                         <br/>
                        <label> 
                            {key.charAt(0).toUpperCase() + key.slice(1)}:
                            <input
                                type="text"
                                name={key}
                                value={formData[key]}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                ))}
                    <p></p>
                    <br/>
                <button variant="primary" className='button' type="submit">Kullanıcı Oluştur</button>
            </form>
        </div>
    );
};
export { CreateUser };