import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import sosoappImage from '../images/img/sosoapp-1.png';
import sosoLogo from '../images/sosoAppL_188.png';
import unionImage from '../images/img/union.svg';
import arrow7Image from '../images/img/arrow-7.svg';
import group2224 from '../images/img/group-1000002224.png';
import star15Image from '../images/img/star-15.svg';
import star16Image from '../images/img/star-16.svg';
import boximage from '../images/img/boximage.svg';
import saglik1 from '../images/img/saglik1.svg';
import yildiz1 from '../images/img/yildiz1.svg';
import { login } from './Login.js';
import  "./SignIn.css";

const SignIn = ({ setIsAuthenticated }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  /* const handleSubmit = (e) => {
    e.preventDefault();
    // Kullanıcı adı ve şifrenin doğruluğunu kontrol ediyoruz
    if (username === 'admin' && password === 'admin') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      // Ana sayfaya yönlendirme yapın
      navigate('/');
      setModalVisible(false);
    } else {
      // Hatalı giriş durumunda uyarı verin
      alert('Yanlış kullanıcı adı veya şifre!');
    }
  }; */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAuthenticated = await login(username, password);
    if (isAuthenticated) {
      setIsAuthenticated(true);
      navigate('/');
      setModalVisible(false);
    }
  };
  const openModal = () => {
    setModalVisible(true);
  };

  // Modalı kapatma fonksiyonu
  const closeModal = () => {
    setModalVisible(false);
  };

  return (

      <div className="div">
        <div className="div-2">
          <div className="div-3">
            <img
              loading="lazy"
              srcSet={sosoLogo}
              className="img"
            />
            <div className="div-4">Explore our soso</div>
            <div className="div-5">
              Yeni nesil şirket içi mesajlaşma uygulaması olarak tasarladığımız sosoAPP ile şirket içi iletişimini kolaylaştırıyoruz. Gelişimimizde sizlerden gelen yeni fikir ve öneriler sayesinde firmanız iç haberleşme dinameğini daha da güçlendiriyoruz.
              Genel olarak mesajlaşma servisi gibi görünsede sosoAPP ile aslında firmanızda görevler oluşturup bu görevlerin süreç takibi yapabilirsiniz. Bu sayede firmanızda süreç yönetimini daha iyi bir şekilde yapabilirsiniz. Gömülü sistemde
              bulunan raporlama modülü ile firmanızda oluşturulan görevlerin raporlamasını alabilirsiniz. Envanter yönetimi modülü sayesinde heryerden firmanızın envanterini takip edebilirsiniz.  5s ve IGS modülleri ile fabrika içi düzeni ve iş kazalarının azaltılması ve önlenmesi konularında en büyük yardımcınız sosoAPP olacaktır.
            </div>
          </div>
          <div className="div-6">
            <div className="div-7"> <button onClick={openModal} className="login-button">GİRİŞ</button></div>
            <div className="div-8">
              <div className="div-9">
                <div className="column">
                  <img
                    loading="lazy"
                    srcSet={sosoappImage}
                    className="img-2"
                  />
                </div>
                <div className="column-2">
                  <div className="div-10">
                    <div className="div-11">Future is <strong>sosoAPP</strong>
                    <br/>
                   <div className="div-12">Şirket içi güvenli mesajlaşma uygulaması</div> 
                   <div className="div-12">
                      Yeni tasarım ile daha modüller ve kullanıcı dostu arayüzü
                      ile kullanımı kolay
                    </div>
                    <br/>
                    </div>
               
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-13">
          <div className="div-14">
            <div className="column-3">
              <div className="div-15">
                <div className="div-16">İstermisiniz?</div>
                <div className="div-17">
                  <div className="div-18">
                    <div className="div-19">our tool demand</div>
                    <img
                      loading="lazy"
                      src={star16Image}
                      className="img-3"
                    />
                  </div>
                  <div className="div-20">
                    the new ui design system powering the world. the new ui
                    design system powering the world.
                  </div>
                  <img
                    loading="lazy"
                    src={boximage}
                    className="img-4"
                  />
                </div>
              </div>
            </div>
            <div className="column-4">
              <div className="div-21">
                <div className="div-22">
                  <img
                    loading="lazy"
                    src={unionImage}
                    className="img-5"
                  />
                  <div className="div-23">
                    <div className="div-24">new system protection</div>
                    <img
                      loading="lazy"
                      src={saglik1}
                      className="img-6"
                    />
                  </div>
                  <div className="div-25">
                    Verileriniz isterseniz sizin sunucularınızda istersenizde
                    bizim güvenlir bulut sunucularımızda
                  </div>
                  <div className="div-26">
                    <div className="div-27">40%</div>
                    <img
                      loading="lazy"
                      src={yildiz1}
                      className="img-7"
                    />
                  </div>
                  <div className="div-28">
                    Süreç yönetiminizi en iyi iyileştiren uygulama
                  </div>
                </div>
                <div className="div-29">
                  <div className="div-30">our best Solutions</div>
                  <div className="div-31">
                    <div className="div-32">Tasks</div>
                    <div className="div-33">HR-Saffely</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalVisible && (
  <div className="modal active" onClick={closeModal}> {/* Modalın dışına tıkladığında kapat */}
  <div className="modal-content" onClick={e => e.stopPropagation()}> {/* Modal içeriğinin tıklama olayını durdur */}
   
           
            <h2>PANEL GİRİŞİ  <span className="close-button" onClick={closeModal}>&times;</span></h2>
          
             <br/>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Kullanıcı Adı"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <br/>
                  <br/>
                  <input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="clearfix"></div>
                  <br/>
                  <button className='button' type="submit">Giriş Yap</button>
                  <br/>
                  <br/>
                  <p className="message">Firma Kayıt işlemi yapmak istiyor musunuz? <strong><a href="#">Oluştur...</a></strong></p>
                </form>
           
           
          </div>
          </div>
         
      )}
        </div>





 
 
    
    
   
  );
};

export default SignIn;
